<template>
      <div class=" col-xs-12 col-sm-12 col-md-12 ">
        <div  class="d-flex justify-content-between check-info-content-body px-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 py-2">
            <div class="check-info-content-body-head">Sığorta şirkəti</div>
            <div class="check-info-content-body-text">
              {{ companyName }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
            <div class="check-info-content-body-head"> Müqavilə nömrəsi</div>
            <div id="contract" class="check-info-content-body-text">
              {{ policyNumber }}
              <img src="@/assets/images/ins-copy.svg" @click="copyText('contract')" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 py-2 ">
            <div class="check-info-content-body-head">Sığorta haqqı</div>
            <div class="check-info-content-body-text">
              {{ premiumAzn }}
              <span>AZN</span>
            </div>
          </div>
        </div>
        <div class="mt-3">
            <div class="iframe-section" style="height: 100vh;">
              <iframe src="https://www.e-pul.az/epay/az/category/insurance/qala_insurance" frameborder="0"
                title="description"></iframe>
            </div>
        </div>

      </div>
  </template>
  
  <script>
  
  
  export default {
    props: ["loading", "data"],
    data: () => ({
      homeURL: null,
      premiumAzn:0,
      companyName:null,
      policyNumber:null
    }),
    mounted() {
        this.homeURL = this.$route.path.startsWith("/travel");
        const params = this.$route.query;
        this.policyNumber = params.policyNumber;
        this.companyName= params.companyName;
        this.premiumAzn = params.premiumAzn;
    },
    methods: {
      copyText(id) {
        var copyText = document.getElementById(id).textContent;
        navigator.clipboard.writeText(copyText);
      },
    },
  };
  </script>
  
  